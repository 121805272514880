<template>
  <div class="p-4 p-md-5">
    <h3 class="text-center mb-2">NLU Web Portal</h3>
    <h6 class="text-center text-muted">Account Activation</h6>
    <div class="row justify-content-center py-md-2">
      <div class="col-lg-9">
        <b-form @submit.prevent="submit">
          <b-form-group
            label="Email address"
            invalid-feedback="Valid Email Address is required"
          >
            <b-form-input
              type="email"
              disabled
              v-model="$v.form.email.$model"
              :state="validateState($v.form.email)"
              class="i-ctrl"
            />
          </b-form-group>
          <b-form-group
            label="Password"
            invalid-feedback="Password must be atleast 8 characters"
          >
            <b-form-input
              type="password"
              v-model="$v.form.password.$model"
              :state="validateState($v.form.password)"
              class="i-ctrl"
            />
          </b-form-group>
          <b-form-group
            label="Confirm Password"
            invalid-feedback="Password must match"
          >
            <b-form-input
              type="password"
              v-model="$v.form.confirm_password.$model"
              :state="validateState($v.form.confirm_password)"
              class="i-ctrl"
            ></b-form-input>
          </b-form-group>
          <b-button type="submit" class="w-100 mt-2" variant="danger">
            <b-spinner small v-show="submitting"></b-spinner>
            Activate
          </b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";
import * as api from "./api";
export default {
  mixins: [validationMixin],
  data() {
    return {
      form: {
        email: "",
        password: "",
        confirm_password: "",
      },
      submitting: false,
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(8),
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  mounted(){
    this.form.email = decodeURI(this.$route.query['email_address']);
  },
  methods: {
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) return false;
      this.submitting = true;
      const activation_code = this.$route.query['activation_code'];
      api.activate({...this.form,activation_code}).then((res)=>{
        this.submitting=false;
        const data = res.data;
        const {token,first_name,last_name,account_type,phone_no,permissions} = data;
        const email = this.form.email;
        
        this.$store.dispatch("auth/logIn",{email,token,first_name,last_name,phone_no,account_type,permissions}).then((rules)=>{
          this.$ability.update(rules);
          if(this.$can("approve_application")){
            this.$router.push({path:"/",query:{status:"verified"}});
          }else if(this.$can("view_reports")){
            this.$router.push({path:"/reports"});
          }else{
            this.$router.push({path:"/"});
          }
        });
      })

    },
  },
};
</script>

<style>
</style>